import styled from 'styled-components';

import { CoreIconProps } from './CoreIcon';

export const StyledIconWrapper = styled.div<{ margin: CoreIconProps['margin'] }>`
  position: relative;
  display: inline-block;
  width: 80px;
  height: 80px;
  margin: ${({ margin }) => margin || '0'};
`;
