import React, { FC } from 'react';

import ArticleTeaser from 'components/molecules/ArticleTeaser/ArticleTeaser';
import { ArticleTile, ArticleTileProps } from 'components/molecules/ArticleTile/ArticleTile';
import IconLink from 'components/atoms/IconLink/IconLink';
import Typography from 'components/atoms/Typography/Typography';

import useMedia from 'hooks/useMedia';
import Button from 'utils/types/Button';
import UploadedImg from 'utils/types/UploadedImg';

import {
  StyledContainer,
  StyledTextColumn,
  StyledWrapper,
  StyledTitleWrapper,
  StyledUpperRow,
  StyledDescriptionWrapper,
  StyledLowerRow,
  StyledMainTileWrapper,
  StyledSecondaryTileContainer,
  StyledLinkWrapper
} from './LatestArticles.styles';

export type LatestArticlesProps = {
  articles: Array<ArticleTileProps>;
  description: string;
  header: string;
  mediaIcon: { icon: UploadedImg };
  socialLink: Button;
};

const renderCta = ({ header, description, socialLink, mediaIcon }): JSX.Element => (
  <StyledTextColumn>
    <StyledTitleWrapper>
      <Typography variant="h1" renderAs="h2" weight="700" color="primary">
        {header}
      </Typography>
    </StyledTitleWrapper>
    <StyledDescriptionWrapper>
      <Typography variant="body1" weight="200" color="primary">
        {description}
      </Typography>
    </StyledDescriptionWrapper>
    <StyledLinkWrapper>
      <IconLink button={socialLink} image={mediaIcon.icon} />
    </StyledLinkWrapper>
  </StyledTextColumn>
);

const renderDesktop = ({ header, description, socialLink, articles, mediaIcon }: LatestArticlesProps): JSX.Element => {
  const [firstArticle, ...otherArticles] = articles;

  return (
    <StyledContainer>
      <StyledWrapper>
        <StyledUpperRow>
          {renderCta({ header, description, socialLink, mediaIcon })}
          <StyledMainTileWrapper>
            <ArticleTeaser
              articleUrl={firstArticle.href}
              authors={[]}
              date={firstArticle.date}
              image={firstArticle.image}
              title={firstArticle.title}
            />
          </StyledMainTileWrapper>
        </StyledUpperRow>
        {otherArticles.length > 0 && (
          <StyledLowerRow>
            {otherArticles.map(({ id, date, href, image, readTime, title }) => (
              <StyledSecondaryTileContainer key={id}>
                <ArticleTile date={date} href={href} image={image} readTime={readTime} title={title} />
              </StyledSecondaryTileContainer>
            ))}
          </StyledLowerRow>
        )}
      </StyledWrapper>
    </StyledContainer>
  );
};

const renderMobile = ({
  header,
  description,
  socialLink,
  articles: [firstArticle],
  mediaIcon
}: LatestArticlesProps) => (
  <StyledWrapper>
    <div>{renderCta({ header, description, socialLink, mediaIcon })}</div>
    <ArticleTile
      date={firstArticle.date}
      href={firstArticle.href}
      image={firstArticle.image}
      readTime={firstArticle.readTime}
      title={firstArticle.title}
    />
  </StyledWrapper>
);

export const LatestArticles: FC<LatestArticlesProps> = (props) => {
  const { isMobile } = useMedia();

  return isMobile ? renderMobile(props) : renderDesktop(props);
};
