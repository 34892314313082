import styled from 'styled-components';

import { mq } from 'theme/media';

export const StyledContainer = styled.div`
  padding-bottom: ${({ theme }) => theme.spacing(9)};

  ${mq.large`
    padding-bottom: ${({ theme }) => theme.spacing(14)};
  `}
`;

export const StyledUpperRow = styled.div`
  ${mq.large`
    display: flex;
    justify-content: space-between;
  `}
`;

export const StyledWrapper = styled.div`
  position: relative;
  z-index: 1;
  color: ${({ theme }) => theme.colors.white};
`;

export const StyledTextColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${mq.large`
    align-items: flex-start;
    padding-top: ${({ theme }) => theme.spacing(2)};
  `}
`;

export const StyledTitleWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing(2)};
  text-align: center;

  ${mq.large`
    text-align: left;
    margin-bottom: ${({ theme }) => theme.spacing(3)};
  `}
`;

export const StyledDescriptionWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing(5)};

  ${mq.large`
    width: 390px;
  `}

  ${mq.xLarge`
    width: 400px;
  `}
`;

export const StyledLinkWrapper = styled.div`
  margin: ${({ theme }) => theme.spacing(4)} 0;
  display: flex;
`;

export const StyledLowerRow = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: ${({ theme }) => theme.spacing(6)};
  color: ${({ theme }) => theme.colors.primaryBlack};

  ${mq.large`
    padding-top: ${({ theme }) => theme.spacing(4)};
    margin-bottom: ${({ theme }) => theme.spacing(4)};
  `};
`;

export const StyledSecondaryTileContainer = styled.div`
  height: 396px;
`;

export const StyledMainTileWrapper = styled.div`
  width: 601px;
  height: 365px;

  ${mq.xsLarge`
    width: 647px;
    margin-top: ${({ theme }) => theme.spacing(3)};
  `}
`;
