import styled from 'styled-components';

export const StyledContainer = styled.div`
  align-items: center;
  display: flex;
`;

export const StyledIconWrapper = styled.div`
  margin-right: ${({ theme }) => theme.spacing(3)};
  width: 40px;
  height: 40px;
  position: relative;
`;

export const StyledTextWrapper = styled.div`
  color: ${({ theme }) => theme.colors.light};
`;
