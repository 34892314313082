import styled from 'styled-components';

export const StyledContainer = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.colors.grey_25};
  color: ${({ theme }) => theme.colors.primaryBlack};
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  position: relative;
  overflow: hidden;
`;

export const StyledImageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 168px;
  border-radius: 4px 0;
  overflow: hidden;
`;

export const StyledLine = styled.div`
  width: 75px;
  height: 4px;
  background: ${({ theme }) => theme.colors.green_200};
`;

export const StyledInfo = styled.div`
  height: ${({ theme }) => theme.spacing(26)};
  padding: ${({ theme }) => theme.spacing(3, 2)};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const StyledDate = styled.span`
  opacity: 0.75;
  padding-bottom: ${({ theme }) => theme.spacing(0.5)};
`;

export const StyledReadInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: ${({ theme }) => theme.spacing(2)};
`;

export const StyledReadTime = styled.span`
  color: ${({ theme }) => theme.colors.green_300};
`;
