import React, { FC } from 'react';
import Image from '@next/image';

import Typography from 'components/atoms/Typography/Typography';
import { AppLink } from 'components/AppLink/AppLink';

import UploadedImg from 'utils/types/UploadedImg';
import Button from 'utils/types/Button';
import fromCMS from 'utils/fromCMS';

import { StyledContainer, StyledIconWrapper, StyledTextWrapper } from './IconLink.styles';

export type IconLinkProps = {
  button: Button;
  image: UploadedImg;
};

const IconLink: FC<IconLinkProps> = ({ button, image }) => (
  <AppLink href={button.href}>
    <StyledContainer>
      <StyledIconWrapper>
        <Image src={fromCMS(image.file.url)} alt={image.alt} layout="fill" objectFit="contain" hasFade={false} />
      </StyledIconWrapper>
      <StyledTextWrapper>
        <Typography weight="800" transform="uppercase" variant="label">
          {button.buttonCopy}
        </Typography>
      </StyledTextWrapper>
    </StyledContainer>
  </AppLink>
);

export default IconLink;
