import React, { FC } from 'react';
import Image from '@next/image';

import { StyledIconWrapper } from './CoreIcon.styles';

export type CoreIconProps = {
  margin: string;
  src: string;
  alt: string;
};

const CoreIcon: FC<CoreIconProps> = ({ src, alt, margin }) => (
  <StyledIconWrapper margin={margin}>
    <Image src={src} alt={alt} layout="fill" objectFit="cover" />
  </StyledIconWrapper>
);

export default CoreIcon;
